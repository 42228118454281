import React from "react";
import Navbar from "./components/Navbar";

function App() {
  return (
    <div className="flex flex-col h-max">
      <Navbar />
      <div className="grid grid-cols-6 gap-4 align-middle">
        <div className="col-start-2 col-span-4 my-4 h-10 bg-neutral-800 rounded-lg text-neutral-400 text-xl text-mono text-center align-middle">
          Coming Soon
        </div>
      </div>
    </div>
  );
}

export default App;
